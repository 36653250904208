import { RawStrings } from "./types";


const frStrings: RawStrings = {
	welcome: "Bienvenue !",
	Unauthorized: "Non autorisé",
	INVALID_CREDENTIALS: "Identifiant et/ou mot de passe invalide",
	MISSING_GUESTS: "Des invités sont manquants",
	NO_PLANNING: "Aucune planification pour cet utilisateur pour le moment",
	INVALID_PASSWORD: "Mot de passe actuel non valide",
	WEAK_PASSWORD: "Le mot de passe est trop faible",
	EMPTY_FIELDS: "Certains champs sont requis",
	INVALID_SELECTED_MEAL_ITEMS: "Éléments manquant pour certain repas",
	GENERIC_HTTP_ERROR: "Aucune donnée disponible",
	INCORRECT_ENTRY: "Entrée erronée",
	login: "Se connecter",
	welcome_title: "Bienvenue sur My Eat Well",
	welcome_text1: "Le planificateur pour organiser vos semaines de menus selon vos préférences.",
	welcome_text2: "Pour vous et toute votre famille. Adapté à tous les régimes alimentaires.",
	welcome_text3: "Planifiez sur plusieurs semaines, ajoutez à votre liste de courses et le tour est joué ! Gérez les préférences alimentaires de votre famille ainsi que de vos convives réguliers.",
	login_connection: "Connexion",
	login_remember_me: "Se souvenir de moi",
	login_error_invalid_credentials: "Identifiant et/ou mot de passe invalide",
	login_validation_error: "Veuillez saisir un email et un mot de passe",
	login_login: "Identifiant",
	login_password: "Mot de passe",
	logout: "Se déconnecter",
	forgot_password: "Mot de passe oublié ?",
	forgot_validation_error: "Veuillez saisir un email",
	forgot_password_message: "Saisissez l'adresse email associé à votre compte",
	forgot_password_email_message: "Un email vous sera envoyé avec un lien pour récupérer votre mot de passe, si un compte existe avec l'email saisie.",
	back_to_login: "Retour à la page de login",
	reset_password: "Réinitialiser son mot de passe",
	password_change: "Mot de passe modifié avec succès",
	email: "Email",
	password: "Mot de passe",
	new_password: "Nouveau mot de passe",
	confirm_password: "Confirmer le mot de passe",
	error_reset_password: "Les mots de passe ne correspondent pas",
	need_help: "Besoin d'aide ?",
	submit: "Valider",
	CGU: "CGU",
	online_service: "Nos services en ligne",
	legal_information: "Mentions Légales",
	cookies_management: "Protection des données et Gestion des cookies",
	data_protection: "Protection des données",
	next: "Suivant",
	plannification: "Planification",
	menus: "Menus",
	foyer: "Foyer",
	favoris: "Favoris",
	courses: "Courses",
	account_parameters: "Paramètres",
	week_plannification: "Planification de vos semaines",
	organize_week: "Organisez vos semaines de menus selon vos préférences",
	choose_your_start_date: "Choisissez la date de départ pour commencer votre planning.",
	week: "Semaine",
	week_end: "week-end",
	your_weeks: "Vos semaines",
	person: "personne",
	persons: "personnes",
	family_member: "Membres du foyer",
	regular_member: "Convives réguliers",
	guests: "Invités",
	guests2: "Convives",
	monday: "Lundi",
	thuesday: "Mardi",
	wednesday: "Mercredi",
	thursday: "Jeudi",
	friday: "Vendredi",
	saturday: "Samedi",
	sunday: "Dimanche",
	Lunch: "Déjeuner",
	Dinner: "Dîner",
	starter: "Entrée",
	main: "Plat",
	cheese: "Fromage",
	dessert: "Dessert",
	bread: "Pain",
	accompaniment: "Accompagnement",
	plannification_button_1: "Planifier la semaine suivante",
	plannification_button_2: "Utiliser cette planification pour les ##NB_WEEKS## semaines",
	plannification_button_3: "Générer une semaine seulement",
	plannification_button_4: "Voir les menus",
	date_unavailable: "Date de départ indisponible, veuillez utiliser le planificateur pour en choisir une",
	menu_unavailable: "Aucun menu disponible pour cette semaine",
	menu_informations: "Informations",
	delete: "Supprimer",
	activate: "Activer",
	validate_week: "Valider la semaine",
	see_previous_week: "Voir la semaine précédente",
	see_next_week: "Voir la semaine suivante",
	from: "Du",
	to: "au",
	refresh: "Nouveau",
	FamilyMember: "Membre du foyer",
	RegularGuest: "Invité réguliers",
	add_person_household: "Ajouter une personne au foyer",
	add_person_guest: "Ajouter un invité",
	view_eating_habits: "Voir les habitudes alimentaires",
	Man: "Homme",
	Woman: "Femme",
	year_old: "ans",
	meters: "mètres",
	kg: "kg",
	physical_activity: "Activité physique",
	Low: "Faible",
	Medium: "Modérée",
	High: "Intense",
	back_to_household: "Retour au foyer",
	diabete_type: "Type de diabète",
	allergens_title: "Allergènes et intolérances alimentaires",
	foods_exclusions: "Exclusions alimentaires",
	favourite_recipes: "Recettes favorites",
	validated_weeks: "Semaines validées",
	add_on: "Ajouté le",
	born_on: "Né(e) le",
	menu_type_week: "Menu type de la semaine",
	menu_type_weekend: "Menu type du week-end",
	name: "Nom",
	title_change_password: "Modifier son mot de passe",
	old_password: "Mot de passe actuel",
	edit_password: "Modifier le mot de passe",
	confirm_password_change: "Votre mot de passe a bien été modifié",
	edit_member: "Modifier un membre",
	attach: "Charger",
	add_member: "Ajouter un membre",
	profile_photo: "Photo de profil",
	validate: "Valider",
	message_send: "Votre message a bien été envoyé.",
	first_name: "Prénom",
	last_name: "Nom",
	birth_date: "Date de naissance",
	weight: "Poids",
	height: "Taille",
	pregnant: "Enceinte",
	breastfeeding: "Allaitante",
	yes: "Oui",
	no: "Non",
	shopping_list: "Liste de courses",
	shopping_list_unavailable: "Liste de courses non disponible pour cette semaine",
	print: "Imprimer",
	add_product: "Ajouter un produit",
	your_preferences: "Vos préférences",
	no_bio: "Pas de produits bio",
	some_bio: "Quelques produits bio",
	much_as_possible_bio: "Le plus possible",
	meal_kits_available: "Plats disponibles en kits ou en plats cuisinés",
	planned_for: "Prévu pour le",
	preparation: "Préparation",
	cooking: "Cuisson",
	total_cost: "Coût total",
	difficulty: "Difficulté",
	see_bread_portions: "Voir les portions de pain",
	recipe: "Recette",
	portions: "Portions",
	nutritional_information: "Infos nutritionnelles",
	total_time: "Temps total",
	cost: "Coût",
	note_recipe: "Noter la recette",
	add_note: "Ajouter une note",
	ingredients: "Ingrédients",
	calories_apport: "Apport calorique par convive",
	nutritional_declaration: "Déclaration nutritionnelle pour 100 g de la recette",
	energy: "Energie",
	lipid: "Matière grasses (g)",
	fatty_acid: "dont acides gras saturés (g)",
	glucid: "Glucides (g)",
	sugar: "dont sucres (g)",
	fiber: "Fibres alimentaires (g)",
	protein: "Protéines (g)",
	salt: "Sel (mg)",
};

export default frStrings;
