import { PlannificationActionTypes, Plannifications_StartDateActionTypes, Plannifications_CopyWeekActionTypes, Plannifications_SetSaveActionTypes } from "./types";
import { CATEGORY_RAPID, CATEGORY_ECO, CATEGORY_TAKE_AWAY, CATEGORY_GOURMET, STRUCTURE_MAIN, STRUCTURE_STARTER, STRUCTURE_CHEESE, STRUCTURE_DESSERT, ADD_PERSON_DAY, SET_ENABLED_DAY, ADD_GUESTS, ADD_START_DATE, COPY_WEEK, REMOVE_PLANNING_WEEK_STATE, SET_PLANNING_SAVE } from "./types";

export function AddCategoryDay(
    type: typeof CATEGORY_RAPID | typeof CATEGORY_ECO | typeof CATEGORY_TAKE_AWAY | typeof CATEGORY_GOURMET | typeof STRUCTURE_STARTER | typeof STRUCTURE_MAIN | typeof STRUCTURE_CHEESE | typeof STRUCTURE_DESSERT, 
    week: number, 
    day: number, 
    lunchType: string, 
    valueChange?: boolean
): PlannificationActionTypes {
    return {
        type: type,
        day: day,
        week: week,
        lunchType: lunchType,
        valueChange: valueChange,
    };
}

export function AddPersonDay(
    week: number, 
    day: number, 
    lunchType: string, 
    persons: object
): PlannificationActionTypes {

    return {
        type: ADD_PERSON_DAY,
        day: day,
        week: week,
        lunchType: lunchType,
        persons: persons,
    };
}

export function SetEnabledDay(week: number, day: number, lunchType: string, valueChange?: boolean): PlannificationActionTypes {

    return {
        type: SET_ENABLED_DAY,
        day: day,
        week: week,
        lunchType: lunchType,
        valueChange: valueChange
    };
}

export function AddStartDate(start_date: Date): Plannifications_StartDateActionTypes {
    return {
        type: ADD_START_DATE,
        start_date: start_date,
    }
}

export function AddGuest(
    week: number, 
    day: number, 
    lunchType: string, 
    nbAdults: number, 
    nbChildren: number
): PlannificationActionTypes {
    return {
        type: ADD_GUESTS,
        day: day,
        week: week,
        lunchType: lunchType,
        nbAdults: nbAdults,
        nbChildren: nbChildren,
    };
}

export function CopyWeek(week: number, nbIteration: number): Plannifications_CopyWeekActionTypes {
    return {
        type: COPY_WEEK,
        week: week,
        iteration: nbIteration,
    }
}

export function SetPlanningIsSave(isSave: boolean): Plannifications_SetSaveActionTypes {
    return {
        type: SET_PLANNING_SAVE,
        is_save: isSave,
    }
}

export function RemovePlanningWeekStore() {
    return {
        type: REMOVE_PLANNING_WEEK_STATE,
    }
}