import * as React from 'react';
// import { useDispatch } from 'react-redux';
import useStrings from 'src/localization/Localization';
import { WeekProfile } from "src/api/dtos/user/UserPreferencesRequest";
import { Link, IconButton } from "@mui/material";
import IconButtonSvgIcon from './IconButtonSvgIcon';
import { useDispatch } from 'react-redux';
import { AddCategoryDay } from 'src/stores/eatinghabits/meal/actions';
import {
    CATEGORY_RAPID, CATEGORY_ECO, CATEGORY_TAKE_AWAY, CATEGORY_GOURMET,
    STRUCTURE_STARTER, STRUCTURE_MAIN, STRUCTURE_CHEESE, STRUCTURE_DESSERT
} from "src/stores/plannification/days/types";
import { MealIsWeekend } from 'src/stores/eatinghabits/meal/types';

type CardEatingHabitsDayCategoryProps = {
    typeLunch: string,
    structure: any,
    isWeekend?: boolean,
};

const CardEatingHabitsDayCategory: React.FunctionComponent<CardEatingHabitsDayCategoryProps> = ({
    typeLunch = "Diner",
    structure,
    isWeekend = false
}) => {
    const _dispatch = useDispatch();
    const _strings = useStrings();
    const mealIsWeekend = isWeekend ? MealIsWeekend.WEEKEND : MealIsWeekend.WEEKDAY;

    const ClockIcon = <IconButtonSvgIcon width={18} height={18} source="/images/planning/clock.svg" />
    const ClockIconActive = <IconButtonSvgIcon width={18} height={18} source="/images/planning/clock-hover.svg" />

    const EuroIcon = <IconButtonSvgIcon width={16} height={18} source="/images/planning/euro.svg" />
    const EuroIconActive = <IconButtonSvgIcon width={18} height={18} source="/images/planning/euro-hover.svg" />

    const ShoppingBagIcon = <IconButtonSvgIcon width={17} height={18} source="/images/planning/shopping-bag.svg" />
    const ShoppingBagIconActive = <IconButtonSvgIcon width={17} height={18} source="/images/planning/shopping-bag-hover.svg" />

    const GourmetIcon = <IconButtonSvgIcon width={18} height={18} source="/images/planning/chef.svg" />
    const GourmetIconActive = <IconButtonSvgIcon width={18} height={18} source="/images/planning/chef-hover.svg" />

    function handleCategoryClicked(event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>) {
        event.preventDefault();

        let dataToLoadTMP = {} as WeekProfile;
        dataToLoadTMP = structure;

        switch (event.currentTarget.ariaLabel) {
            case "rapid":
                dataToLoadTMP.is_quick = !dataToLoadTMP.is_quick;
                _dispatch(AddCategoryDay(CATEGORY_RAPID, typeLunch, mealIsWeekend, dataToLoadTMP.is_quick));
                break;
            case 'eco':
                dataToLoadTMP.is_economic = !dataToLoadTMP.is_economic;
                _dispatch(AddCategoryDay(CATEGORY_ECO, typeLunch, mealIsWeekend, dataToLoadTMP.is_economic));
                break;
            case 'takeAway':
                dataToLoadTMP.is_take_away = !dataToLoadTMP.is_take_away;
                _dispatch(AddCategoryDay(CATEGORY_TAKE_AWAY, typeLunch, mealIsWeekend, dataToLoadTMP.is_take_away));
                break;
            case 'gourmet':
                dataToLoadTMP.is_gourmet = !dataToLoadTMP.is_gourmet;
                _dispatch(AddCategoryDay(CATEGORY_GOURMET, typeLunch, mealIsWeekend, dataToLoadTMP.is_gourmet));
                break;
            case "starter":
                dataToLoadTMP.has_starter = !dataToLoadTMP.has_starter;
                _dispatch(AddCategoryDay(STRUCTURE_STARTER, typeLunch, mealIsWeekend, dataToLoadTMP.has_starter));
                break;
            case "main":
                dataToLoadTMP.has_main = !dataToLoadTMP.has_main;
                _dispatch(AddCategoryDay(STRUCTURE_MAIN, typeLunch, mealIsWeekend, dataToLoadTMP.has_main));
                break;
            case "cheese":
                dataToLoadTMP.has_cheese = !dataToLoadTMP.has_cheese;
                _dispatch(AddCategoryDay(STRUCTURE_CHEESE, typeLunch, mealIsWeekend, dataToLoadTMP.has_cheese));
                break;
            case "dessert":
                dataToLoadTMP.has_dessert = !dataToLoadTMP.has_dessert;
                _dispatch(AddCategoryDay(STRUCTURE_DESSERT, typeLunch, mealIsWeekend, dataToLoadTMP.has_dessert));
                break;
        }
    }

    return (
        <>
            <div style={{ borderTop: "solid 1px gray", textAlign: "center" }}>
                <IconButton aria-label="rapid" onClick={(e) => { handleCategoryClicked(e) }}>
                    {structure && structure.is_quick ? ClockIconActive : ClockIcon}
                </IconButton>
                <IconButton aria-label="eco" onClick={(e) => { handleCategoryClicked(e) }}>
                    {structure && structure.is_economic ? EuroIconActive : EuroIcon}
                </IconButton>
                <IconButton aria-label="takeAway" onClick={(e) => { handleCategoryClicked(e) }}>
                    {structure && structure.is_take_away ? ShoppingBagIconActive : ShoppingBagIcon}
                </IconButton>
                <IconButton aria-label="gourmet" onClick={(e) => { handleCategoryClicked(e) }}>
                    {structure && structure.is_gourmet ? GourmetIconActive : GourmetIcon}
                </IconButton>
            </div>
            <div className="planning-structure" style={{ borderTop: "solid 1px gray", textAlign: "center" }}>
                <div>
                    <Link underline="none" color="inherit" aria-label="starter" onClick={(e) => { handleCategoryClicked(e) }}>
                        <span className={structure && structure.has_starter ? 'active' : ""}>{_strings.starter}</span>
                    </Link>
                </div>
                <div>
                    <Link underline="none" color="inherit" aria-label="main" style={{ pointerEvents: "none", opacity: "0.5" }} >
                        <span className={structure && structure.has_main ? 'active' : ""}>{_strings.main}</span>
                    </Link>
                </div>
                <div>
                    <Link underline="none" color="inherit" aria-label="cheese" onClick={(e) => { handleCategoryClicked(e) }} >
                        <span className={structure && structure.has_cheese ? 'active' : ""}>{_strings.cheese}</span>
                    </Link>
                </div>
                <div>
                    <Link underline="none" color="inherit" aria-label="dessert" onClick={(e) => { handleCategoryClicked(e) }}>
                        <span className={structure && structure.has_dessert ? 'active' : ""}>{_strings.dessert}</span>
                    </Link>
                </div>
            </div>
        </>
    );

}

export default CardEatingHabitsDayCategory;
