import * as React from "react";
import Footer from "src/components/bases/Footer";
import MenuButton from "src/components/bases/MenuButton";

const DiabetePage: React.FunctionComponent = (props) => {
	return (
		<div>
			<MenuButton></MenuButton>

			<section id="diabete" className="marginPages">
				<header>
					<h3>Protection des données et Cookies</h3>
				</header>

				<div className="container-fluid">
					<p className="maj">Mise à jour: 31/10/2024</p>
					<p>
						Cette politique est publiée dans le respect du Règlement
						général de protection des données (RGPD), à la Directive
						ePrivacy, ainsi qu'à la loi n° 78-17 du 6 janvier 1978
						dite Informatique et Libertés.{" "}
					</p>

					<div>
						<header>
							<h4>
								Politique de protection des données à caractère
								personnel et des données de santé
							</h4>
						</header>
						<p>
							Soucieux de votre santé, l’équipe My GoodLife
							sécurise au mieux vos données à caractère personnel
							et vos données de santé. My GoodLife s’engage à
							établir une relation de confiance basée sur la
							sécurité de nos systèmes d’informations, la
							protection des données à caractère personnel et une
							collecte de vos données personnelles limitées au
							strict nécessaire (Minimisation des données).
						</p>
						<p>
							{" "}
							Les données concernant la santé (poids, IMC,
							allergie alimentaires) sont hébergées chez un
							hébergeur certifié HDS : Microsoft Azure. Les
							datacenters sont situés en Union Européenne.
						</p>
					</div>

					<div>
						<header>
							<h4>
								Bases légales des traitements de données
								personnelles
							</h4>
						</header>
						<p>
							Les traitements mis en œuvre par le formulaire de
							contact est basé sur l’intérêt légitime.
						</p>
						<p>
							La création d’un compte client et les services de
							l’application liés au planning nutritionnel ont pour
							base légale le contrat.
						</p>
					</div>
					<div>
						<header>
							<h4>Durée de conservation des données</h4>
						</header>
						<p>
							La conservation des informations de compte est de 6
							mois après la suppression de celui-ci.
						</p>
					</div>

					<div>
						<header>
							<h4>Droit des personnes concernées</h4>
						</header>
						<div>
							<p>
								Pour toute information ou exercice de vos droits
								concernant la collecte, le traitement ou
								l’utilisation de vos données, vous pouvez
								contacter le délégué à la protection des données
								(DPO) :{" "}
							</p>
							<ul>
								<li>
									<p style={{ fontWeight: "bold" }}>
										Par courrier à l’adresse suivante :{" "}
									</p>

									<div>
										<p>
											My GoodLife<br />
											A l’attention du délégué à la
											protection des données (DPO)<br />
											5, rue Fénelon<br />
											33000 BORDEAUX<br />
										</p>

									</div>
								</li>
								<li>
									<span style={{ fontWeight: "bold" }}>
										Par mail :{" "}
										<a href="mailto:dpo@my-goodlife.fr">
											dpo@my-goodlife.fr
										</a>{" "}
									</span>
									<p>
											La réglementation en vigueur sur
											l’exercice des droits des personnes
											inclus vos droits d’accès, de
											rectification, de suppression,
											d’opposition, limitation et
											portabilité le cas échéant.
										</p>
										<p>
											Afin de garantir la confidentialité
											et la sécurité de vos données, la
											société My GoodLife pourra procéder
											à une vérification de votre
											identité.
										</p>
									
								</li>
							</ul>
						</div>
					</div>

					<div>
						<header>
							<h4>Confidentialité du Mot de passe</h4>
						</header>
						<p>
							Vous êtes responsable de la confidentialité du mot
							de passe que vous avez choisi pour accéder à votre
							compte sur notre Application.
						</p>
						<p>
							Vous vous engagez à conserver ce mot de passe secret
							et à ne le communiquer à personne.
						</p>
					</div>

					<div>
						<header>
							<h4>
								Cookies nécessaire au fonctionnement de
								l'application
							</h4>
						</header>
						<div>
							<p>
								Actuellement Good Angel n’utilise pas de cookie
								sur son application Good Deal. Seulement un
								Token d’identification.
							</p>
						</div>
					</div>

					<div>
						<header>
							<h4>Les destinataires des données</h4>
						</header>
						<p>
							Les données peuvent être transférées à nos
							prestataires/sous-traitants (hébergement Microsoft
							Azure) qui sont soumis à nos règles. Ils
							interviennent dans la réalisation des services liés
							à My GoodLife.
						</p>
					</div>

					<div>
						<header>
							<h4>Transfert de données Hors Union Européenne</h4>
						</header>
						<p>
							Aucune de vos données ne sera transférée en dehors
							de la zone Européenne.
						</p>
					</div>
					<div>
						<header>
							<h4>
								Les données à caractères personnelles des
								mineurs
							</h4>
						</header>
						<p>
							Le service My GoodLife peut prendre en charge des
							mineurs, il appartient aux parents de décider si
							leur enfant est autorisé à utiliser le service My
							GoodLife.
						</p>
					</div>
					<br />
					<div>
						<p>© Copyright 2024 My Eat Well. All Rights Reserved</p>
					</div>
				</div>
			</section>

			<Footer></Footer>
		</div>
	);
};

export default DiabetePage;
