import { 
    CATEGORY_RAPID, 
    CATEGORY_ECO, 
    CATEGORY_TAKE_AWAY, 
    CATEGORY_GOURMET, 
    STRUCTURE_STARTER, 
    STRUCTURE_MAIN, 
    STRUCTURE_CHEESE, 
    STRUCTURE_DESSERT 
} from "src/stores/plannification/days/types";

import { 
    SET_ENABLED_DAY, 
    ADD_GUESTS, 
    ADD_PERSON_DAY, 
} from "src/stores/plannification/days/types";

import { MealActionTypes } from "./types";

import { MealIsWeekend } from "./types";

export function AddCategoryDay(
    type: typeof CATEGORY_RAPID | typeof CATEGORY_ECO | typeof CATEGORY_TAKE_AWAY | typeof CATEGORY_GOURMET | typeof STRUCTURE_STARTER | typeof STRUCTURE_MAIN | typeof STRUCTURE_CHEESE | typeof STRUCTURE_DESSERT, 
    lunchType: string, 
    isWeekend: MealIsWeekend,
    valueChange?: boolean
): MealActionTypes {
    return {
        type: type,
        isWeekend: isWeekend,
        lunchType: lunchType,
        valueChange: valueChange,
    };
}

export function AddPersonDay(
    isWeekend: MealIsWeekend,
    lunchType: string, 
    guest_ids: object
): MealActionTypes {

    return {
        type: ADD_PERSON_DAY,
        isWeekend: isWeekend,
        lunchType: lunchType,
        guest_ids: guest_ids,
    };
}

export function SetEnabledDay(
    isWeekend: MealIsWeekend,
    lunchType: string, 
    valueChange?: boolean
): MealActionTypes {

    return {
        type: SET_ENABLED_DAY,
        isWeekend: isWeekend,
        lunchType: lunchType,
        valueChange: valueChange
    };
}

export function AddGuest(
    isWeekend: MealIsWeekend,
    lunchType: string, 
    added_adults: number, 
    added_children: number
): MealActionTypes {
    return {
        type: ADD_GUESTS,
        isWeekend: isWeekend,
        lunchType: lunchType,
        added_adults: added_adults,
        added_children: added_children,
    };
}