
import * as React from 'react';

import { FromStore } from "src/helpers/Store";
import { useDispatch } from "react-redux";
import useStrings from "src/localization/Localization";

import { 
    CATEGORY_RAPID, CATEGORY_ECO, CATEGORY_TAKE_AWAY, CATEGORY_GOURMET, STRUCTURE_STARTER, 
    STRUCTURE_MAIN, STRUCTURE_CHEESE, STRUCTURE_DESSERT 
} from "src/stores/plannification/days/types";

import { Card, Row } from "react-bootstrap";

import { Box, Button } from "@mui/material";
import { Meal } from 'src/api/dtos/menu/MenuProfile';
import { WeekProfile } from "src/api/dtos/user/UserPreferencesRequest";
import { AddCategoryDay, AddGuest, AddPersonDay, SetEnabledDay } from "src/stores/eatinghabits/meal/actions";
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CardEatingHabitsDayPerson from './CardEatingHabitsDayPerson';
import CardEatingHabitsDayCategory from './CardEatingHabitsDayCategory';
import { MealIsWeekend } from 'src/stores/eatinghabits/meal/types';

type CardEatingHabitsDayProps = {
    dayName: string,
    headerBgColor?: string,
    typeLunch: string,
    isWeekend?: boolean,
};

const CardEatingHabitsDay: React.FunctionComponent<CardEatingHabitsDayProps> = ({
    dayName,
    typeLunch,
    isWeekend,
    headerBgColor,
} : CardEatingHabitsDayProps) => {
    const _dispatch = useDispatch();
    const _strings = useStrings();
    const _user = FromStore(state => state.apis.profile);

    const defaultPref = {
        is_enabled: true,
        added_adults: 0,
        added_children: 0,
        has_starter: false,
        has_main: true,
        has_cheese: false,
        has_dessert: false,
        is_quick: false,
        is_economic: false,
        is_take_away: false,
        is_gourmet: false,
        guest_ids: [],
    } as WeekProfile;

    const [userIsLoaded, setUserIsLoaded] = React.useState(false);
    const [structure, setStructure] = React.useState(defaultPref);

    React.useEffect(() => {
        if (structure) {
            let newStructure = structure;
            newStructure.has_main = true;
            setStructure(newStructure);
            const mealIsWeekend = isWeekend ? MealIsWeekend.WEEKEND : MealIsWeekend.WEEKDAY;
            _dispatch(AddCategoryDay(STRUCTURE_MAIN, typeLunch, mealIsWeekend, true));

            let data: {} | null = null;
            if (_user.isLoaded) {
                setUserIsLoaded(true);
                if (!isWeekend) {
                    if (typeLunch == Meal.Lunch) {
                        data = _user.user.week_lunch;
                    } else if (typeLunch == Meal.Dinner) {
                        data = _user.user.week_dinner;
                    } 
                } else {
                    if (typeLunch == Meal.Lunch) {
                        data = _user.user.weekend_lunch;
                    } else if (typeLunch == Meal.Dinner) {
                        data = _user.user.weekend_dinner;
                    }
                }
            } 

            if (data) {
                Object.entries(data as object).forEach(([category, value]) => {
                    switch (category) {
                        case "is_quick": case "rapid":
                            newStructure.is_quick = value;
                            _dispatch(AddCategoryDay(CATEGORY_RAPID, typeLunch, mealIsWeekend, value));
                            break;
                        case "is_economic": case "eco":
                            newStructure.is_economic = value;
                            _dispatch(AddCategoryDay(CATEGORY_ECO, typeLunch, mealIsWeekend, value));
                            break;
                        case "is_take_away": case "takeAway":
                            newStructure.is_take_away = value;
                            _dispatch(AddCategoryDay(CATEGORY_TAKE_AWAY, typeLunch, mealIsWeekend, value));
                            break;
                        case "is_gourmet": case "gourmet":
                            newStructure.is_gourmet = value;
                            _dispatch(AddCategoryDay(CATEGORY_GOURMET, typeLunch, mealIsWeekend, value));
                            break;
                        case "has_starter": case "starter":
                            newStructure.has_starter = value;
                            _dispatch(AddCategoryDay(STRUCTURE_STARTER, typeLunch, mealIsWeekend, value));
                            break;
                        case "has_main": case "main":
                            newStructure.has_main = value;
                            _dispatch(AddCategoryDay(STRUCTURE_MAIN, typeLunch, mealIsWeekend, value));
                            break;
                        case "has_cheese": case "cheese":
                            newStructure.has_cheese = value;
                            _dispatch(AddCategoryDay(STRUCTURE_CHEESE, typeLunch, mealIsWeekend, value));
                            break;
                        case "has_dessert": case "dessert":
                            newStructure.has_dessert = value;
                            _dispatch(AddCategoryDay(STRUCTURE_DESSERT, typeLunch, mealIsWeekend, value));
                            break;
                        case "guest_ids": case "persons":
                            newStructure.guest_ids = value;
                            _dispatch(AddPersonDay(mealIsWeekend, typeLunch, value));
                            break;
                        case "added_adults": case "nbAdults":
                            newStructure.added_adults = value;
                            _dispatch(AddGuest(mealIsWeekend, typeLunch, value, newStructure.added_children));
                            break;
                        case "added_children": case "nbChildren":
                            newStructure.added_children = value;
                            _dispatch(AddGuest(mealIsWeekend, typeLunch, newStructure.added_adults, value));
                            break;
                        case "is_enabled": case "isEnabled":
                            newStructure.is_enabled = value;
                            _dispatch(SetEnabledDay(mealIsWeekend, typeLunch, value));
                            break;
                    }
                });

                setStructure(newStructure);
            }
        }   
    }, [_user.isLoaded]);

    function handleActivateMenu(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        let newStructure = structure;
        if(!newStructure) {
            newStructure = defaultPref;
        } 

        newStructure.is_enabled = !newStructure.is_enabled;
        
        setStructure(newStructure);
        _dispatch(SetEnabledDay(isWeekend ? MealIsWeekend.WEEKEND : MealIsWeekend.WEEKDAY, typeLunch, newStructure.is_enabled));
    }

    if (!userIsLoaded) {
        return (
            <div className="w-full flex justify-center">
                <CachedRoundedIcon fontSize="large" className="animate-spin2" />
            </div>
        );
    } else {
        return (
            <Row style={{ margin: "1% 0px" }}>
                <Card style={{
                    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                    padding: '0px', pointerEvents: (structure && !structure.is_enabled ? 'none' : 'all'), 
                    opacity:  (!structure.is_enabled) ? `0.3` : 'inherit'
                }}
                >
                    {(headerBgColor !== undefined) ?
                        (<Card.Header style={{ backgroundColor: `${headerBgColor}`, color: "white", textAlign: "center", fontWeight: 800, fontSize: "15px" }}>{dayName}</Card.Header>)
                        : null}    

                    <Card.Body style={{ padding: "0px 0px 5px 0px" }}>
                        <div>
                            <CardEatingHabitsDayPerson 
                                lunchType={typeLunch}
                                added_adults={structure && structure.added_adults ? structure.added_adults : 0}
                                added_children={structure && structure.added_children ? structure.added_children : 0}
                                guest_ids={structure && structure.guest_ids ? structure.guest_ids : []}
                                setStructure={setStructure}
                                structure={structure}
                                isWeekend={isWeekend}
                            />
                            <CardEatingHabitsDayCategory 
                                typeLunch={typeLunch}
                                structure={structure}
                                isWeekend={isWeekend}
                            />
                        </div>

                    </Card.Body>
                </Card>


                <div style={{ textAlign: 'center', marginTop: "15px" }}>
                    <Box sx={{
                        "& .MuiButton-root": {
                            color: structure && structure.is_enabled ? 'red' : '#8fc740',
                            borderRadius: "25px",
                            border: structure && structure.is_enabled ? "2px solid red" : "2px solid #8fc740",
                            textTransform: "capitalize",
                            padding: "0 20px",
                            fontWeight: "200",
                        }
                    }}
                    >
                        <Button onClick={handleActivateMenu}>{structure && structure.is_enabled ? _strings.delete : _strings.activate}</Button>
                    </Box>
                </div>

            </Row >
        )
    }
};

export default CardEatingHabitsDay;