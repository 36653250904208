import { createBrowserHistory } from 'history';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ConfigureStore from './stores/store';

import LoginGate from './components/authentications/LoginGate';
import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import PlanningPage from './pages/PlanningPage';
import MenuPage from './pages/MenuPage';
import MenuDetailPage from './pages/MenuDetailPage';
import HouseholdPage from './pages/HouseholdPage';
import MemberDetailPage from './pages/MemberDetailPage';
import MemberAddPage from './pages/MemberAddPage';
import EatingHabitsPage from './pages/EatingHabitsPage';
import BookmarksPage from './pages/BookmarksPage';
import ShoppingListPage from './pages/ShoppingListPage';
// import ShoppingListKitPage from './pages/ShoppingListKitPage';

import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AccountPage from './pages/AccountPage';
import CGUPage from './pages/CGUPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import LegalNoticesPage from './pages/LegalNoticesPage';
import CookiesPage from './pages/CookiesPage';
import DiabetePage from'./pages/DiabetePage';
import ContactUsPage from './pages/ContactUsPage';

const store = ConfigureStore();

let history = createBrowserHistory();
history.listen((location, action) => {
    window.scrollTo(0, 0);
});

const AppRouter: React.FunctionComponent = props => {
    function render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/forgotpassword" component={ForgotPasswordPage} />
                    <Route exact path="/resetpassword/reset/:token" component={ResetPasswordPage} />
                    <Route exact path="/CGU" component={CGUPage} />
                    <Route exact path="/LegalNotices" component={LegalNoticesPage} />
                    <Route exact path="/DataPolicy-Cookies" component={CookiesPage} />
					<Route exact path="/Diabete" component={DiabetePage} />
					<Route exact path="/Contact" component={ContactUsPage}/>
                    {/* <Route exact path="/Authentications/PasswordChange?resetToken=:token" component={ResetPasswordPage} /> */}
                    <LoginGate>
                        <Route exact path="/calendar" component={DashboardPage} />
                        <Route exact path="/planning" component={PlanningPage} />
                        <Route exact path="/menu" component={MenuPage} />
                        <Route exact path="/menudetail" component={MenuDetailPage} />
                        <Route exact path="/household" component={HouseholdPage} />
                        <Route exact path="/memberdetail" component={MemberDetailPage} />
                        <Route exact path="/memberadd" component={MemberAddPage} />
                        <Route exact path="/eatinghabits" component={EatingHabitsPage} />
                        <Route exact path="/bookmarks" component={BookmarksPage} />
                        <Route exact path="/shopping" component={ShoppingListPage} />
                        {/* <Route exact path="/shoppingkits" component={ShoppingListPage} /> */}
                        <Route exact path="/account" component={AccountPage} />
                    </LoginGate>
                </Switch>
            </Router>
        );
    }

    return render();
}

const container = document.getElementById('main');
const root = createRoot(container!);

root.render(
    <React.Fragment>
        <Provider store={store.store}>
            <PersistGate loading={null} persistor={store.persistor}>
                <AppRouter />
            </PersistGate>
        </Provider>
    </React.Fragment>
);