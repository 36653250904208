
// export const API_HOST = "https://api.mew.fr";
export const API_HOST = "https://mew-api.azurewebsites.net";
// export const API_HOST = "http://localhost:8000";

export const APP_VERSION = "~ DEV ~";
const API_ROOT = "/api/v1";

export default class Urls {

    public static LOGIN_URL = API_ROOT + "/authentications/token";
    public static REFRESH_URL = API_ROOT + "/authentications/token";

	public static GET_USER_PROFILE_URL = API_ROOT + "/me";
	public static SET_USER_PROFILE_URL = API_ROOT + "/me";
	public static SAVE_USER_PREFERENCES = API_ROOT + "/me/preferences";
	public static RESET_PASSWORD_URL = API_ROOT + "/authentications/password/reset";
	public static UPDATE_PASSWORD_URL = API_ROOT + "/authentications/password";

	// public static GET_PERSONS = API_ROOT + "/people";
	public static GET_CONFIGURATION = API_ROOT + "/configurations";
	public static SAVE_PLANNING = API_ROOT + "/plannings";
	public static SAVE_PLANNING_WEEK = API_ROOT + "/plannings/weeks";
	public static GET_MEALS = API_ROOT + "/meals"
	public static PATCH_MEALS = API_ROOT + "/meals/elements"
	public static GET_MENU_GENERATE = API_ROOT + "/plannings/generate"
	public static GET_RECIPES = API_ROOT + "/recipes"
	public static GET_RECIPES_PICTURE = API_ROOT + "/picture"

	public static ADD_GUEST = API_ROOT + "/guests";
	public static EDIT_GUEST = API_ROOT + "/guests";
	public static DELETE_GUEST = API_ROOT + "/guests";
}

export class Constants {

    public static CLIENT_ID = "web_client";
	public static CLIENT_SECRET = "goodangelweb";
}
