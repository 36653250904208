import * as React from 'react';

import AvatarLetter from "src/components/bases/AvatarLetter"
import { Form, InputGroup } from "react-bootstrap";
import { Avatar } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import useStrings from 'src/localization/Localization';
import { FromStore } from 'src/helpers/Store';
import { useDispatch } from 'react-redux';

import { ApiGetUserProfile} from 'src/stores/apis/user/actions';
import { ExpandMore } from '@mui/icons-material';
import { AddGuest, AddPersonDay } from 'src/stores/eatinghabits/meal/actions';

import { MealIsWeekend } from 'src/stores/eatinghabits/meal/types';

type CardEatingHabitsDayPersonProps = {
    lunchType: string,
    guest_ids: any,
    added_adults: number,
    added_children: number,
    structure: any,
    setStructure: any,
    isWeekend?: boolean,
};

const CardEatingHabitsDayPerson: React.FunctionComponent<CardEatingHabitsDayPersonProps> = ({
    lunchType,
    guest_ids = {},
    added_adults = 0,
    added_children = 0,
    structure,
    setStructure,
    isWeekend,
}) => {
    const _strings = useStrings();
    const _dispatch = useDispatch();
    const _persons = FromStore(state => state.apis.profile.user.guests);
    const [activeAvatar, setActiveAvatar] = React.useState([] as boolean[]);
    const [personsToAdd, setPersonToAdd] = React.useState([] as number[]);
    const [nbPersonAvatar, setNbPersonAvatar] = React.useState(0);
    const [nbAdult, setNbAdult] = React.useState(0);
    const [nbChildren, setNbChildren] = React.useState(0);
    const mealIsWeekend = isWeekend ? MealIsWeekend.WEEKEND : MealIsWeekend.WEEKDAY;

    React.useEffect(() => {
        setNbPersonAvatar(guest_ids ? Object.keys(guest_ids).length : 0);
        setNbAdult((added_adults > 0 ? added_adults : 0));
        setNbChildren((added_children > 0 ? added_children : 0));
        let personAdd = personsToAdd;
        let avatar = activeAvatar;
        if(guest_ids) {
            for (let i = 0; i < Object.keys(guest_ids).length; i++) {
                avatar[guest_ids[i]] = true;
                personAdd[guest_ids[i]] = guest_ids[i];
            }
        }
        setActiveAvatar(avatar);
        setPersonToAdd(personAdd);  
    } , [guest_ids, added_adults, added_children]);

    let guestList = new Array();
    if (_persons) {
        Object.entries(_persons).forEach(([index, guestInfo]) => {
            if (!guestList[guestInfo.group]) {
                guestList[guestInfo.group] = new Array();
            }
            guestList[guestInfo.group].push(guestInfo);
        });
    }

    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 3px",
        },
    }));

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore />}
            {...props}
        />
    ))(({ theme }) => ({
        padding: '5px',
        height: '40px',
        textAlign: 'center',
        '& .MuiAccordionSummary-content': {
            display: 'block',
        },
    }));

    const [expanded, setExpanded] = React.useState<string | false>('');
    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
        
    let totalPersons = 0;
    if(guest_ids || added_adults || added_children) {
        totalPersons = Object.keys(guest_ids).length + added_adults + added_children;
    }

    function handleActivePerson(event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>, personId: number) {
        event.preventDefault();

        let avatarPerson = activeAvatar;
        avatarPerson[personId] = !avatarPerson[personId];
        setActiveAvatar(avatarPerson);
        let pToAdd = personsToAdd;
        if (avatarPerson[personId] === true) {
            setNbPersonAvatar(nbPersonAvatar + 1);
            pToAdd[personId] = personId;
            setPersonToAdd(pToAdd);
        } else {
            setNbPersonAvatar(nbPersonAvatar - 1);
            pToAdd.splice(pToAdd.indexOf(personId), 1);
            setPersonToAdd(pToAdd);
        }

        let persons = pToAdd.filter((item, index) => pToAdd.indexOf(item) === index);

        let newStructure = structure;
        newStructure.guest_ids = persons;
        setStructure(newStructure);
        _dispatch(AddPersonDay(mealIsWeekend, lunchType, persons));

        _dispatch(ApiGetUserProfile());
    }

    function handleOtherPersonClick(event: React.ChangeEvent<HTMLInputElement>) {
        // const handleOtherPersonClick = (event:React.WheelEvent<HTMLInputElement>)  => {
        event.preventDefault();

        const target = event.target as HTMLInputElement;
        const value = target.valueAsNumber;
        const invite = (event.target.validity.valid) ? value : nbAdult;
        // const invite = value;
        let adult = nbAdult;
        let children = nbChildren;
        if (target.id == 'adults') {
            setNbAdult(invite);
            adult = invite;
        }
        if (target.id == 'children') {
            setNbChildren(invite);
            children = invite;
        }
        let structureTMP = structure;
        structureTMP.added_adults = adult;
        structureTMP.added_children = children;
        setStructure(structureTMP);
        _dispatch(AddGuest(mealIsWeekend, lunchType, adult, children));

        _dispatch(ApiGetUserProfile());
    }

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                {totalPersons + ' ' + ((totalPersons <= 1) ? `${_strings.person}` : `${_strings.persons}`)}
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ fontSize: '13px' }}>
                    <div>
                        <div style={{ textAlign: 'center' }}>{_strings.family_member}</div>
                        <div style={{ textAlign: 'center' }}>
                            {
                                (guestList != undefined && guestList['FamilyMember'] != undefined) ?
                                    [...guestList['FamilyMember']].map((val: any, index: any) => {
                                        var indexPerson = -1;
                                        if (guest_ids) {
                                            for (var i = 0; i < Object.keys(guest_ids).length; i++) {
                                                if (guest_ids[i] === val.id) {
                                                    indexPerson = i;
                                                    break;
                                                }
                                            }
                                        }

                                        if (val.picture_file) {
                                            return (<Avatar key={index} variant="rounded" onClick={(e) => { handleActivePerson(e, val.id) }} className={(guest_ids && guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} style={{ top: "6px" }} sx={{ display: "inline-flex", width: "30px", height: "30px", margin: "2px 2px" }} alt={`${val.last_name} ${val.first_name}`} src={val.picture_file} />);
                                        } else {
                                            return (<AvatarLetter variant="rounded" className={(guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} key={index} fullName={`${val.last_name} ${val.first_name}`} onClick={(e) => { handleActivePerson(e, val.id) }} />);
                                        }
                                    })
                                    : null
                            }
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'center' }}>{_strings.regular_member}</div>
                        <div style={{ textAlign: 'center' }}>
                            {
                                (guestList != undefined && guestList['RegularGuest'] != undefined) ?
                                    [...guestList['RegularGuest']].map((val: any, index: any) => {
                                        var indexPerson = -1;
                                        if (guest_ids) {
                                            for (var i = 0; i < Object.keys(guest_ids).length; i++) {
                                                if (guest_ids[i] === val.id) {
                                                    indexPerson = i;
                                                    break;
                                                }
                                            }
                                        }

                                        if (val.picture_file) {
                                            return (<Avatar key={index} variant="rounded" onClick={(e) => { handleActivePerson(e, val.id) }} className={(guest_ids && guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} style={{ top: "6px" }} sx={{ display: "inline-flex", width: "30px", height: "30px", margin: "2px 2px" }} alt={`${val.last_name} ${val.first_name}`} src={val.picture_file} />);
                                        } else {
                                            return (<AvatarLetter variant="rounded" className={(guest_ids && guest_ids[indexPerson]) ? 'avatar_active' : 'avatar_inactive'} key={val.id} fullName={`${val.last_name} ${val.first_name}`} onClick={(e) => { handleActivePerson(e, val.id) }} />)
                                        }
                                    })
                                    : null
                            }
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'center' }}>{_strings.guests}</div>
                        <Form.Group style={{ width: "fit-content", margin: 'auto' }}>
                            <InputGroup>
                                <InputGroup.Text style={{ fontSize: "13px" }}>
                                    Adultes
                                </InputGroup.Text>
                                <Form.Control type="number" pattern="[0-9]*" id='adults' value={nbAdult} min={0} max={10} onInput={handleOtherPersonClick}/>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text style={{ fontSize: "13px" }}>
                                    Enfants
                                </InputGroup.Text>
                                <Form.Control type="number" pattern="[0-9]*" id='children' value={nbChildren} min={0} max={10} onInput={handleOtherPersonClick}  /*onWheel={handleOtherPersonClick}*/ />
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>

    )
}

export default CardEatingHabitsDayPerson;
