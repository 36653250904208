import { 
    CATEGORY_RAPID, 
    CATEGORY_ECO, 
    CATEGORY_TAKE_AWAY, 
    CATEGORY_GOURMET, 
    STRUCTURE_STARTER, 
    STRUCTURE_MAIN, 
    STRUCTURE_CHEESE, 
    STRUCTURE_DESSERT 
} from "src/stores/plannification/days/types";

import { 
    SET_ENABLED_DAY, 
    ADD_GUESTS, 
    ADD_PERSON_DAY,
} from "src/stores/plannification/days/types";

export enum MealIsWeekend {
    WEEKDAY = "Weekday",
    WEEKEND = "Weekend"
}


export interface MealState {
    is_quick: boolean,
    is_economic: boolean,
    is_take_away: boolean,
    is_gourmet: boolean,
    has_starter: boolean,
    has_main: boolean,
    has_cheese: boolean,
    has_dessert: boolean,
    is_enabled: boolean,
    added_adults: number,
    added_children: number,
    guest_ids: object
}

export interface MealActionTypes {
    type: typeof CATEGORY_RAPID | typeof CATEGORY_ECO | typeof CATEGORY_TAKE_AWAY | typeof CATEGORY_GOURMET | typeof STRUCTURE_STARTER | typeof STRUCTURE_MAIN | typeof STRUCTURE_CHEESE | typeof STRUCTURE_DESSERT | typeof ADD_PERSON_DAY | typeof ADD_GUESTS | typeof SET_ENABLED_DAY,
    lunchType: string,
    isWeekend: MealIsWeekend,
    added_adults?: number,
    added_children?: number,
    guest_ids?: object,
    iteration?: number,
    valueChange?: boolean
}
