import { 
    CATEGORY_RAPID, 
    CATEGORY_ECO, 
    CATEGORY_TAKE_AWAY, 
    CATEGORY_GOURMET, 
    STRUCTURE_STARTER, 
    STRUCTURE_MAIN, 
    STRUCTURE_CHEESE, 
    STRUCTURE_DESSERT 
} from "src/stores/plannification/days/types";

import { 
    SET_ENABLED_DAY, 
    ADD_GUESTS, 
    ADD_PERSON_DAY, 
} from "src/stores/plannification/days/types";

import { MealIsWeekend, MealState } from "./types";
import { MealActionTypes } from "./types";
import { Meal } from "src/api/dtos/menu/MenuProfile";

export function MealReducers(state: MealState, action: MealActionTypes) {
    let mealState = {
        is_quick: false,
        is_economic: false,
        is_take_away: false,
        is_gourmet: false,
        has_starter: false,
        has_main: false,
        has_cheese: false,
        has_dessert: false,
        is_enabled: true,
        added_adults: 0,
        added_children: 0,
        guest_ids: {},
    }

    if (state && state[action.isWeekend] && state[action.isWeekend][action.lunchType]) {
        mealState = state[action.isWeekend][action.lunchType];
    }

    let available = {
        is_quick: mealState.is_quick,
        is_economic: mealState.is_economic,
        is_take_away: mealState.is_take_away,
        is_gourmet: mealState.is_gourmet,
        has_starter: mealState.has_starter,
        has_main: mealState.has_main,
        has_cheese: mealState.has_cheese,
        has_dessert: mealState.has_dessert,
        is_enabled: mealState.is_enabled,
        added_adults: mealState.added_adults,
        added_children: mealState.added_children,
        guest_ids: mealState.guest_ids,
    }

    switch (action.type) {
        case (CATEGORY_RAPID):
            available.is_quick = action.valueChange != undefined ? action.valueChange : !available.is_quick;
            break;
        case CATEGORY_ECO:
            available.is_economic = action.valueChange != undefined ? action.valueChange : !available.is_economic;
            break;
        case CATEGORY_TAKE_AWAY:
            available.is_take_away = action.valueChange != undefined ? action.valueChange : !available.is_take_away;
            break;
        case CATEGORY_GOURMET:
            available.is_gourmet = action.valueChange != undefined ? action.valueChange : !available.is_gourmet;
            break;
        case STRUCTURE_STARTER:
            available.has_starter = action.valueChange != undefined ? action.valueChange : !available.has_starter;
            break;
        case STRUCTURE_MAIN:
            available.has_main = action.valueChange != undefined ? action.valueChange : !available.has_main;
            break;
        case STRUCTURE_CHEESE:
            available.has_cheese = action.valueChange != undefined ? action.valueChange : !available.has_cheese;
            break;
        case STRUCTURE_DESSERT:
            available.has_dessert = action.valueChange != undefined ? action.valueChange : !available.has_dessert;
            break;
        case ADD_PERSON_DAY:
            available.guest_ids = (action.guest_ids ? action.guest_ids : available.guest_ids);
            break;
        case ADD_GUESTS:
            available.added_adults = (action.added_adults ? action.added_adults : available.added_adults);
            available.added_children = (action.added_children ? action.added_children : available.added_children);
            break;
        case SET_ENABLED_DAY:
            available.is_enabled = action.valueChange != undefined ? action.valueChange : !available.is_enabled;
            break;
    }

    if (action.isWeekend != (undefined || null)) {
        return {
            ...state,
            [action.isWeekend]: {
                ...state[action.isWeekend],
                [action.lunchType]: available
            }
        }
    } else {
        if (state == (undefined || null)) {
            return {
                [MealIsWeekend.WEEKDAY]: {
                    [Meal.Lunch]: available,
                    [Meal.Dinner]: available
                },
                [MealIsWeekend.WEEKEND]: {
                    [Meal.Lunch]: available,
                    [Meal.Dinner]: available
                }
            };
        }
        return state;
    }
}
