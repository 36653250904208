import * as React from "react";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

type ButtonCustomProps = {
    children ?: React.ReactNode,
    label: string,
    type?: "button" | "submit" | "reset",
    disabled?: boolean,
    style: {
        backgroundColor: string,
        color?: string,
        borderColor?: string,
        width?: string,
        borderRadius?: string,
        textTransform?: string,
        textDecoration?: string,
        fontSize?: string,
        justifyContent?: string,
		
    },
    onClick?: (e: any) => void,
};

const ButtonCustom: React.FunctionComponent<ButtonCustomProps> = ({
    label = "",
    type = "button",
    disabled = false,
    style = {
        backgroundColor: "none",
        color: "white",
        borderColor: "none",
        width: "",
        borderRadius: "5px",
        textTransform: "uppercase",
        textDecoration: "none",
        fontSize: 'inherit',
        justifyContent: 'center',
    },
    onClick = (e: any) => { },
    children,
} : ButtonCustomProps) => {

    // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    function hexToRgb(hex: string) {
        const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
        if (normal) return normal.slice(1).map(e => parseInt(e, 16));
        const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
        if (shorthand) return shorthand.slice(1).map(e => 0x11 * parseInt(e, 16));
        return null;
    }

    let styleTemps = {
        backgroundColor: style.backgroundColor,
        color: (style.color != undefined ? style.color : "white"),
        border: (style.borderColor != undefined ? "1px solid " + style.borderColor : "none"),
        borderRadius: (style.borderRadius != undefined ? style.borderRadius : "5px"),
        padding: "10px 20px",
        margin: "10px",
        "&:hover": {
            backgroundColor: "rgb(" + hexToRgb(style.backgroundColor) + ", 0.5)",
        },
        width: (style.width != undefined ? style.width : ""),
    };

    const ButtonCustom = styled(Button)(({ theme }) => (
        styleTemps
    ));

    // const ButtonCustom = styled(Button)(({ theme }) => ({
    //     backgroundColor: props.style.backgroundColor,
    //     color: (props.style.color != undefined ? props.style.color : "white"),
    //     border: (props.style.borderColor != undefined ? "1px solid " + props.style.borderColor : "none"),
    //     borderRadius: (props.style.borderRadius != undefined ? props.style.borderRadius : "5px"),
    //     padding: "10px 20px",
    //     margin: "10px",
    //     "&:hover": {
    //         backgroundColor: "rgb(" + hexToRgb(props.style.backgroundColor) + ", 0.5)",
    //     },
    //     width: (props.style.width != undefined ? props.style.width : ""),
    // }));

    function render(): JSX.Element {
        return (
            <ButtonCustom type={type ? type : "button"} disabled={disabled ? disabled : false} onClick={onClick}>{label}</ButtonCustom>
        )
    }

    return render();

};

export default ButtonCustom;
