import * as React from "react";
import useStrings from "src/localization/Localization";
import { Icon, Link, IconButton } from "@mui/material";

const Footer: React.FunctionComponent = (props) => {
    const _strings = useStrings();

    const ContactIcon = (
        <Icon>
            <img alt="contact" src="/images/menu_bar/envelope-solid.svg" width="20px" height="15px" />
        </Icon>
    );

    const TweeterIcon = (
        <Icon>
            <img alt="tweeter" src="/images/menu_bar/twitter-brands.svg" width="18px" height="15px" />
        </Icon>
    );

    const FacebookIcon = (
        <Icon>
            <img alt="facebook" src="/images/menu_bar/facebook-f-brands.svg" width="8px" height="15px" />
        </Icon>
    );

    const menuOptions = [
        {
            name: "My Good Life",
            link: "https://www.my-goodlife.fr/",
            blank: true,
        },
        {
            name: _strings.CGU,
            link: "/CGU",
            blank: false,
        },
        {
            name: _strings.legal_information,
            link: "/LegalNotices",
        },
        {
            name: _strings.cookies_management,
            link: "/DataPolicy-Cookies",
        },
    ];

    function render(): JSX.Element {
        return (
            <div className="footer" style={{
                backgroundColor: "#096639",
                color: "white",
                padding: "8px 0px",
                /*marginTop: "50px",*/
            }}>
                <div style={{ display: "flex", margin: "0 10%", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center" }}>
                    <div
                        style={{
                            width: "50%", display: "inline-flex", flexDirection: "row"
                        }}
                    >
                        <img src="/images/menu_bar/logo-footer@3x.png"
                            srcSet="/images/menu_bar/logo-footer@2x.png 2x, /images/menu_bar/logo-footer@3x.png 3x"
                            className="logo-footer" />
                    </div>
                    <div
                        style={{
                            width: "70%", display: "inline-flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "flex-end", alignContent: "center"
                        }}
                        className="footer-links"
                    >
                        {menuOptions.map((element, i) => {
                            return (
                                <Link
                                    key={i}
                                    href={element.link}
                                    target={element.blank ? "_blank" : "_self"}
                                    underline="none"
                                    color="inherit"
                                    sx={{ mr: 2, ":hover": {color: "#fd694d"} }}
                                >
                                    {element.name}
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <hr style={{ margin: "8px 0" }} />
                <div style={{ display: "flex", margin: "0 10%" }}>
                    <div
                        style={{
                            width: "50%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignContent: "center"
                        }}

                        className="footer-register"
                    >
                        &copy; {new Date().getFullYear()} My GoodLife, tous droits réservés
                    </div>
                    <div
                        style={{
                            width: "50%", display: "inline-flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "flex-end"
                        }}
                    >
                            <IconButton aria-label="facebook" href="https://www.facebook.com/good.angel.fr/" target={"_blank"} sx={{ ":hover": { color: "#fd694d" } }}>
                                {FacebookIcon}
                            </IconButton>
                            <IconButton aria-label="tweeter" href="https://twitter.com/good_angel_fr" target={"_blank"}>
                                {TweeterIcon}
                            </IconButton>
                            <IconButton aria-label="contact" href="mailto:contact@good-deal.fr">
                                {ContactIcon}
                            </IconButton>
                    </div>
                </div>
            </div>
        );
    }

    return render();
};

export default Footer;
