
import * as React from 'react';

import { useDispatch } from "react-redux";
import { FromStore } from "src/helpers/Store";
import useStrings from "src/localization/Localization";

import { AddCategoryDay, AddGuest, AddPersonDay, SetEnabledDay } from "src/stores/plannification/days/actions";
import { CATEGORY_RAPID, CATEGORY_ECO, CATEGORY_TAKE_AWAY, CATEGORY_GOURMET, STRUCTURE_STARTER, STRUCTURE_MAIN, STRUCTURE_CHEESE, STRUCTURE_DESSERT } from "src/stores/plannification/days/types";

import { Card, Row } from "react-bootstrap";

import { Box, Button } from "@mui/material";
import { Meal } from 'src/api/dtos/menu/MenuProfile';
import { WeekProfile } from "src/api/dtos/user/UserPreferencesRequest";

import CardPlanningDayPerson from './CardPlanningDayPerson';
import CardPlanningDayCategory from './CardPlanningDayCategory';
import { CachedRounded } from '@mui/icons-material';

type CardPlanningDayProps = {
    dayName: string,
    headerBgColor?: string,
    dayNumber: number,
    typeLunch: string,
    weekNumber: number,
    isEmpty?: boolean,
};

const CardPlanningDay: React.FunctionComponent<CardPlanningDayProps> = props => {
    const _dispatch = useDispatch();
    const _strings = useStrings();
    const _categories = FromStore(state => state.plannification.weeks);
    const _user = FromStore(state => state.apis.profile);

    let food_category_exclusions = new Array() as number[];
    if (_user.isLoaded) {
        _user.user.food_category_exclusions.map((element, index) => {
            food_category_exclusions.push(element['id']);
        })
    }

    const defaultPref = {
        is_enabled: true,
        added_adults: 0,
        added_children: 0,
        has_starter: false,
        has_main: true,
        has_cheese: false,
        has_dessert: false,
        is_quick: false,
        is_economic: false,
        is_take_away: false,
        is_gourmet: false,
        guest_ids: [],
        isEmpty: false, // initialiser la propriété isEmpty à false
         
    } as WeekProfile;

    const [userIsLoaded, setUserIsLoaded] = React.useState(false);
    const [structure, setStructure] = React.useState(defaultPref);

    React.useEffect(() => {
        if (structure) {
            let newStructure = structure;
            newStructure.has_main = true;
            setStructure(newStructure);
            _dispatch(AddCategoryDay(STRUCTURE_MAIN, props.weekNumber, props.dayNumber, props.typeLunch));

            let data: {} | null = null;
            if (_user.isLoaded) {
                setUserIsLoaded(true);
                if (_categories && Object.entries(_categories).length === 0) {
                    if ([1, 2, 3, 4, 5].indexOf(props.dayNumber) >= 0) {
                        if (props.typeLunch == Meal.Lunch) {
                            data = _user.user.week_lunch;
                        } else if (props.typeLunch == Meal.Dinner) {
                            data = _user.user.week_dinner;
                        }
                    }
                    if ([6, 7].indexOf(props.dayNumber) >= 0) {
                        if (props.typeLunch == Meal.Lunch) {
                            data = _user.user.weekend_lunch;
                        } else if (props.typeLunch == Meal.Dinner) {
                            data = _user.user.weekend_dinner;
                        }
                    }
                } else {
                    if (_categories && _categories![props.weekNumber] && _categories![props.weekNumber][props.dayNumber] && _categories![props.weekNumber][props.dayNumber][props.typeLunch]) {
                        data = _categories![props.weekNumber][props.dayNumber][props.typeLunch];
                    }
                }

                window.sessionStorage.setItem('user', JSON.stringify(_user.user));

            } else if(window.sessionStorage.getItem('user')) {
                let user = JSON.parse(window.sessionStorage.getItem('user')!);
                setUserIsLoaded(true);
                if (_categories && Object.entries(_categories).length === 0) {
                    if ([1, 2, 3, 4, 5].indexOf(props.dayNumber) >= 0) {
                        if (props.typeLunch == Meal.Lunch) {
                            data = user.week_lunch;
                        } else if (props.typeLunch == Meal.Dinner) {
                            data = user.week_dinner;
                        }
                    }
                    if ([6, 7].indexOf(props.dayNumber) >= 0) {
                        if (props.typeLunch == Meal.Lunch) {
                            data = user.weekend_lunch;
                        } else if (props.typeLunch == Meal.Dinner) {
                            data = user.weekend_dinner;
                        }
                    }
                } else {
                    if (_categories && _categories![props.weekNumber] && _categories![props.weekNumber][props.dayNumber] && _categories![props.weekNumber][props.dayNumber][props.typeLunch]) {
                        data = _categories![props.weekNumber][props.dayNumber][props.typeLunch];
                    }
                }
            }

            if (data) {
                Object.entries(data as object).forEach(([category, value]) => {
                    switch (category) {
                        case "is_quick": case "rapid":
                            newStructure.is_quick = value;
                            _dispatch(AddCategoryDay(CATEGORY_RAPID, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "is_economic": case "eco":
                            newStructure.is_economic = value;
                            _dispatch(AddCategoryDay(CATEGORY_ECO, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "is_take_away": case "takeAway":
                            newStructure.is_take_away = value;
                            _dispatch(AddCategoryDay(CATEGORY_TAKE_AWAY, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "is_gourmet": case "gourmet":
                            newStructure.is_gourmet = value;
                            _dispatch(AddCategoryDay(CATEGORY_GOURMET, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "has_starter": case "starter":
                            newStructure.has_starter = value;
                            _dispatch(AddCategoryDay(STRUCTURE_STARTER, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "has_main": case "main":
                            newStructure.has_main = value;
                            _dispatch(AddCategoryDay(STRUCTURE_MAIN, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "has_cheese": case "cheese":
                            newStructure.has_cheese = value;
                            _dispatch(AddCategoryDay(STRUCTURE_CHEESE, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "has_dessert": case "dessert":
                            newStructure.has_dessert = value;
                            _dispatch(AddCategoryDay(STRUCTURE_DESSERT, props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "guest_ids": case "persons":
                            newStructure.guest_ids = value;
                            _dispatch(AddPersonDay(props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                        case "added_adults": case "nbAdults":
                            newStructure.added_adults = value;
                            _dispatch(AddGuest(props.weekNumber, props.dayNumber, props.typeLunch, value, newStructure.added_children));
                            break;
                        case "added_children": case "nbChildren":
                            newStructure.added_children = value;
                            _dispatch(AddGuest(props.weekNumber, props.dayNumber, props.typeLunch, newStructure.added_adults, value));
                            break;
                        case "is_enabled": case "isEnabled":
                            newStructure.is_enabled = value;
                            _dispatch(SetEnabledDay(props.weekNumber, props.dayNumber, props.typeLunch, value));
                            break;
                    }
                });

                setStructure(newStructure);
            }
        }   
    }, [_user.isLoaded]);

    function handleActivateMenu(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        let newStructure = structure;
        if(!newStructure) {
            newStructure = defaultPref;
        } 

        newStructure.is_enabled =  !newStructure.is_enabled;
        
        setStructure(newStructure);
        _dispatch(SetEnabledDay(props.weekNumber, props.dayNumber, props.typeLunch, newStructure.is_enabled));
    }


    function render(): JSX.Element {
        return (
            <Row style={{ margin: "1% 0px" }}>
                <Card style={{
                    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
                    padding: '0px', pointerEvents: (props.isEmpty || structure && !structure.is_enabled ? 'none' : 'all'), 
                    opacity:  (props.isEmpty || !structure.is_enabled) ? `0.3` : 'inherit'
                }}
                >
                    {(props.headerBgColor !== undefined) ?
                        (<Card.Header style={{ backgroundColor: `${props.headerBgColor}`, color: "white", textAlign: "center", fontWeight: 800, fontSize: "15px" }}>{props.dayName}</Card.Header>)
                        : null}    

                    <Card.Body style={{ padding: "0px 0px 5px 0px" }}>
                        <div>
                            <CardPlanningDayPerson 
                                typeLunch={props.typeLunch}
                                weekNumber={props.weekNumber}
                                dayNumber={props.dayNumber}
                                added_adults={structure && structure.added_adults ? structure.added_adults : 0}
                                added_children={structure && structure.added_children ? structure.added_children : 0}
                                guest_ids={structure && structure.guest_ids ? structure.guest_ids : []}
                                setStructure={setStructure}
                                structure={structure}
                            />
                            <CardPlanningDayCategory 
                                typeLunch={props.typeLunch}
                                weekNumber={props.weekNumber}
                                dayNumber={props.dayNumber}
                                structure={structure}
                            />
                        </div>

                    </Card.Body>
                </Card>


                <div style={{ textAlign: 'center', marginTop: "15px", visibility: (props.isEmpty ? "hidden" : "visible") }}>
                    <Box sx={{
                        "& .MuiButton-root": {
                            color: structure && structure.is_enabled ? 'red' : '#8fc740',
                            borderRadius: "25px",
                            border: structure && structure.is_enabled ? "2px solid red" : "2px solid #8fc740",
                            textTransform: "capitalize",
                            padding: "0 20px",
                            fontWeight: "200",
                        }
                    }}
                    >
                        <Button onClick={handleActivateMenu}>{structure && structure.is_enabled ? _strings.delete : _strings.activate}</Button>
                    </Box>
                </div>

            </Row >
        )
    }

    if (userIsLoaded) {
        return render();
    } else {
        return (
            <div className="w-full flex justify-center">
                <CachedRounded fontSize="large" className="animate-spin2" />
            </div>
        );
    }
};

export default CardPlanningDay;