import { applyMiddleware, combineReducers, createStore } from "redux";
// import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist/lib/types';
import thunkMiddleware from "redux-thunk";
import ApiReducers from "./apis/reducers";
import { AuthReducer } from "./auth/reducers";
import { ConfigurationReducers } from "./configuration/reducers"
import PlannificationReducers from "./plannification/reducers";
// import { ApiPersonsReducers } from "./persons/reducers";
import { MenuReducers } from "./menu/reducers";
import { GeneralAppReducer } from "./page/reducers";
import { StaticDataReducers } from "./static_data/reducers";
import { ShoppingListReducers } from "./shoppingList/reducers";
import { RecipesReducers } from "./recipes/reducers";
import EatingHabitReducers from "./eatinghabits/reducers";

const rootReducers = combineReducers({
    auth: AuthReducer,
    page: GeneralAppReducer,
    apis: ApiReducers,
    configuration: ConfigurationReducers,
    plannification: PlannificationReducers,
    menu: MenuReducers,
    recipes: RecipesReducers,
    static_data: StaticDataReducers,
    shopping_list: ShoppingListReducers,
    eatingHabits: EatingHabitReducers,
});

export type AppState = ReturnType<typeof rootReducers>;
const persistenceConfiguration: PersistConfig<AppState> = {
    key: 'store',
    storage: storage,
    blacklist: ["apis", "page"]
}

const persistedReducer = persistReducer(persistenceConfiguration, rootReducers)


export default function ConfigureStore() {

    // let logger = createLogger();

    let store = createStore(persistedReducer, applyMiddleware(
        thunkMiddleware,
        // logger
    ));

    let persistor = persistStore(store);

    return { store, persistor };
}
