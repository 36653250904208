import * as React from "react";
import Footer from "src/components/bases/Footer";
import MenuButton from "src/components/bases/MenuButton";

const LegalNoticesPage: React.FunctionComponent = (props) => {

    return (
        <div>
            <MenuButton></MenuButton>

            <section id="legaleNotices" className="marginPages">
                <header><h3>Mentions Légales</h3></header>
                <br />
                <div className="container-fluid">
                    <div>
                        Le site my-eatwell.com  est édité par : <br />
                        My Goodlife SAS au capital de 500 000 euros, <br />
                        RCS BORDEAUX 807 543 038 <br />
                        N° identification T.V.A. : FR 00 807543038 <br />
                        Siège social :<br />
                        5, rue Fénelon<br />
                        33000 BORDEAUX<br />
                        Tel. : +33 (0)7 86 48 46 47 – <a href="mailto:contact@my-goodlife.fr">contact@my-goodlife.fr</a> <br />
                        Représentant légal: Karl AUZOU
                    </div>
                    <br />
                    <div>
                        <header><h4>Centre administratif</h4></header>
                        <div>
                            MY GOODLIFE <br />
                            5, rue Fénelon<br />
                            33000 BORDEAUX<br />
                        </div>
                    </div>
                    <br />
                    <div>
                        <header><h4>Data Protection Officer</h4></header>
                        <div>
                            Fabrice Nègre <br />
                            73, chemin des Loriots <br />
                            81710 Saïx <br />
                            <a href="mailto:dpo@my-goodlife.fr">dpo@my-goodlife.fr</a>
                        </div>
                    </div>
                    <br />
                    <div>
                        Le site my-eatwell.com est hébergé par : <br />
                        MICROSOFT AZURE <br />
                        <a href="https://privacy.microsoft.com/fr-fr/privacystatement" target={"_blank"}>https://privacy.microsoft.com/fr-fr/privacystatement</a> <br />
                        data center en Europe occidentale <br />
                        Téléphone : N° Cristal - 09 70 01 90 90 - appel non surtaxé <br />
                        MICROSOFT France <br />
                        RCS Nanterre B 327 733 184 <br />
                        39, quai du Président Roosevelt <br />
                        92130 Issy-les-Moulineaux
                    </div>
                </div>
                <br /><br />
            </section>

            <Footer></Footer>
        </div>
    );
}

export default LegalNoticesPage;